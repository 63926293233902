import { actionTypes } from '../actions/actionTypes'
import {combineReducers} from 'redux';

const getDataPay = (state = { dataPayment: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            }
        case actionTypes.GET_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                dataPayment: action.payload
            }
        case actionTypes.GET_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}

const getTotalPay = (state = { totalPay: 0 }, action) => {
    switch (action.type) {
        case actionTypes.GET_TOTALPAY_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case actionTypes.GET_TOTALPAY_SUCCESS:
            return {
                ...state,
                loading: false,
                totalPay: action.payload
            }
        case actionTypes.GET_TOTALPAY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}

export default combineReducers({
    getDataPay,
    getTotalPay,
})