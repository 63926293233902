import { actionTypes } from '../actions/actionTypes'

const initialState = {
    dataDashboard: {
        this_month: { api: {}, payments: [] },
        today: { api: {} }
    },
    loading: false,
}
export const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DASHBOARD_REQUEST:
            return {
                ...state,
                loading: true,
                // isSuccess: false
            }
        case actionTypes.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                dataDashboard: action.payload
            }
        case actionTypes.GET_DASHBOARD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}