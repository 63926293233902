import { actionTypes } from '../actions/actionTypes';
import { combineReducers } from 'redux';
import Cookies from 'js-cookie';

let token = Cookies.get('token');
const initialState = token ? { isLoggedIn: true } : {};
export const login = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                user: action.user,
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn: false,
                user: action.data,
                isLoggedIn: true,
            };
        case actionTypes.LOGIN_FAIL:
            return {
                ...state,
                loggingIn: false,
                error: action.error,
                user: null,
            };
        case actionTypes.LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
};

export const logout = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case actionTypes.LOGOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default combineReducers({
    login,
    logout,
});
