import { combineReducers } from 'redux';

import auth from './authReducer';
import user from './userReducer';
import { dashboard } from './dashboardReducer';
import payment from './paymentReducer';
import password from './passwordReducer';
import admin from './adminReducer';
import common from './commonReducer';

export default combineReducers({
  auth,
  user,
  password,
  dashboard,
  payment,
  admin,
  common,
});
