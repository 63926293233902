
export function toMap(list) {
    const object = {};
    if (list && Array.isArray(list)) {
        for (let i = 0; i < list.length; i++) {
            object[list[i].id] = list[i];
        }
    }
    return object;
}

export function servicesToMap(services) {
    const object = {};
    for (let i = 0; i < services.length; i++) {
        object[services[i].service_id] = services[i];
    }
    return object;
}

export function customerToMap(customers) {
    // const object = {};
    // for (let i = 0; i < customers.length; i++) {
    //     object[customers[i].id] = customers[i];
    // }
    if (!customers) return {};
    if (customers[0]) {
        return customers[0];
    }
    return {};
}

export function revenueToMap(revenues) {
    const object = {};
    for (let i = 0; i < revenues.length; i++) {
        // const date = moment(revenues[i].date).format('YYYY-MM-DD');
        object[revenues[i].service_id] = revenues[i];
        // object[revenues[i].service_id][date] = revenues[i];
    }
    return object;
}

export function branchTargetToMap(targets) {
    const object = {};
    for (let i = 0; i < targets.length; i++) {
        object[targets[i].shop_id] = targets[i];
    }
    return object;
}

export function cipRevenueToMap(revenues) {
    const object = {};
    for (let i = 0; i < revenues.length; i++) {
        object[revenues[i].partner_id] = revenues[i];
    }
    return object;
}