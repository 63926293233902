import { actionTypes } from '../actions/actionTypes';
import { combineReducers } from 'redux';

const getUser = (state = { user: {} }, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case actionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.RESET_KEY_CONFIRM_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const editUser = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EDIT_USER_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.EDIT_USER_SUCCESS:
      return {
        success: true,
      };
    case actionTypes.EDIT_USER_FAIL:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

const resetKey = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RESET_KEY_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.RESET_KEY_SUCCESS:
      return {
        success: true,
      };
    case actionTypes.RESET_KEY_FAIL:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

const resetKeyConfirm = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RESET_KEY_CONFIRM_REQUEST:
      return {
        loading: true,
      };
    case actionTypes.RESET_KEY_CONFIRM_SUCCESS:
      return {
        success: true,
      };
    case actionTypes.RESET_KEY_CONFIRM_FAIL:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

const getDashboardServiceMonth = (state = { data: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_SERVICE_MONTH_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.GET_DASHBOARD_SERVICE_MONTH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case actionTypes.GET_DASHBOARD_SERVICE_MONTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const getDashboardServiceYear = (state = { data: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_SERVICE_YEAR_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.GET_DASHBOARD_SERVICE_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case actionTypes.GET_DASHBOARD_SERVICE_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const getDashboardServiceCount = (state = { data: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_SERVICE_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.GET_DASHBOARD_SERVICE_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case actionTypes.GET_DASHBOARD_SERVICE_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const getDashboardServiceList = (state = { data: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_SERVICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionTypes.GET_DASHBOARD_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case actionTypes.GET_DASHBOARD_SERVICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  getUser,
  editUser,
  resetKey,
  resetKeyConfirm,
  getDashboardServiceMonth,
  getDashboardServiceYear,
  getDashboardServiceCount,
  getDashboardServiceList,
});
