import { store } from './store';
import authActions from '@mins/redux/auth/actions';

export default () => {
  // if (Auth.getToken()) {
  //   return new Promise(() => {
  //   });
  // }
  return new Promise(() => {});
}
