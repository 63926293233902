import React, { lazy, Suspense, Profiler } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loader from '@mins/components/utility/loader';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Cookies from 'js-cookie';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Notfound = lazy(() => import('./components/404'));

const publicRoutes = [
  // {
  //   path: PUBLIC_ROUTE.LANDING,
  //   exact: true,
  //   component: lazy(() => import('@mins/containers/Pages/SignIn')),
  // },
  // {
  //   path: PUBLIC_ROUTE.SIGN_IN,
  //   component: lazy(() => import('@mins/containers/Pages/SignIn')),
  // },
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@mins/containers/MyPages/LoginPage/LoginPage')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@mins/containers/MyPages/LoginPage/LoginPage')),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() => import('@mins/containers/Authentication/Auth0/Auth0Callback')),
  },
  // {
  //   path: PUBLIC_ROUTE.FORGET_PASSWORD,
  //   component: lazy(() => import('./containers/Pages/ForgetPassword')),
  // },
  // {
  //   path: PUBLIC_ROUTE.RESET_PASSWORD,
  //   component: lazy(() => import('./containers/Pages/ResetPassword')),
  // },
  // {
  //   path: PUBLIC_ROUTE.RESET_PASSWORD_SUCCESS,
  //   component: lazy(() => import('./containers/Pages/ResetPassword/ResetPasswordSuccess')),
  // },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('./containers/MyPages/ForgetPassword/ForgetPassword')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('./containers/MyPages/ResetPassword/ResetPassword')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD_SUCCESS,
    component: lazy(() => import('./containers/MyPages/ResetPassword/ResetPasswordSuccess')),
  },
  {
    path: PUBLIC_ROUTE.RESET_KEY,
    component: lazy(() => import('./containers/MyPages/ResetKey/ResetKey')),
  },
];

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Cvs.auth.login.isLoggedIn);
  // const isLoggedIn = Cookies.get('token');
  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path='/'>
              <Dashboard />
            </PrivateRoute>
            <Route path='*'>
              <Notfound />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
